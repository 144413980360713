// Development Environment
// export const API_BASE_URL = "https://dev.igesia.com";

// test local environment // ML -> changed 8001 to 8000
// export const API_BASE_URL = "http://localhost:8000"

// AWS S3 hosted evviroment => ML
// export const API_BASE_URL = "https://api.uatigesia.com"

//Production Environment => ML
// export const API_BASE_URL = "https://api.uatigesia.com";
// export const HOSTED_BASE_URL = "https://uatigesia-web.com";

//Super Admin Email
export const SUPER_ADMIN_EMAIL = "superadmin@igesia.co"

export const PROD_HOSTED_BASE_URL = "igesia.co" 
export const PROD_HOSTED_API_BASE_URL = "api.igesia.com" 
// export const PROD_HOSTED_API_BASE_URL = "localhost:8000" 

//Production Environment
export const API_BASE_URL = "https://api.igesia.com";
export const USER_EXISTS = "/users/exist";

export const STRIPE_CREATE_SESSION = "/stripe/v1/create";
export const STRIPE_RETRIEVE_SESSION = "/stripe/v1/retrieve";
export const DRIVE_UPLOAD = "/drive/upload";

export const POLL_CREATE = "/poll/create";
export const POLL_SUBMIT = "/poll/submit";
export const POLL_EDIT = "/poll/edit";
export const POLL_ANALYTICS = "/poll/analytics";

export const ROOM_APP_LIST = "/rooms/applist";

export const QUIZ_CREATE = "/quiz/create";
export const QUIZ_SUBMIT = "/quiz/submit";
export const QUIZ_EDIT = "/quiz/edit";
export const QUIZ_RESPONSE_GET = "/quiz/response";

export const INVITE_PROCESS = "/invites/link/process";

export const ASSIGNMENT_CREATE = "/assignment/create";
export const ASSIGNMENT_SHARE = "/assignment/share";
export const ASSIGNMENT_UPDATE = "/assignment/update";
export const ASSIGNMENT_REMOVE = "/assignment/remove";
export const ASSIGNMENT_SUBMIT = "/assignment/submit";
export const ASSIGNMENT_EDIT = "/assignment/edit";
export const ASSIGNMENT_ANALYTICS = "/assignment/analytics";
export const ASSIGNMENT_RESPONSE_GET = "/assignment/response";

export const GRADEBOOK_SCORE_PUBLISH = "/gradebook/score/publish";

export const FORM_RESPONSES = "/form/responses";

export const APP_CLOSE = "/apps/close";

export const JITSI_MEETING = "/clinic/appointments/meeting";

export const EVENTS_LIST = "/events/public/get-events";
export const EVENT_DETAILS = "/events/public/get-event-detail";
export const EVENT_USER_DETAILS = "/events/public/get-user-detail";
export const EVENT_SPEAKERS = "/events/public/get-speakers";
export const EVENT_SCHEDULES = "/events/public/get-schedule";
export const EVENT_BOOTHS = "/events/public/get-booths";
export const EVENT_PAGES = "/events/public/get-pages";
export const EVENT_PARTICIPANTS = "/events/public/get-participants";
export const EVENT_GROUPS_LIST = "/events/public/get-groups";

export const ADMIN_EVENTS_LIST = "/events/admin/get-events";
export const ADMIN_EVENT_SCHEDULES = "/events/admin/get-schedule";

// Zoom meeting
export const ZOOM_SIGNATURE = `${API_BASE_URL}/events/meetings/signature`;
export const ZOOM_API_KEY = "vACgl_ZQSXqvRtEQrLkFLA";

// Room purchase
export const PLAN_ESTIMATE = "/users/plan/estimate";
export const PLAN_STATUS = "/users/plan/status";
export const PLAN_CREATE = "/users/plan/create";
export const PLAN_CANCEL = "/users/plan/cancel";

// Event & Course purchase
export const EVENT_PLAN_STATUS = "/users/event-plan/status";
export const EVENT_PLAN_CREATE = "/users/event-plan/create";
export const EVENT_PLAN_ESTIMATE = "/users/event-plan/estimate";
export const EVENT_PLAN_CANCEL = "/users/event-plan/cancel";

// Course Modules
export const ADMIN_ADD_MODULE = "/events/admin/add-module";
export const ADMIN_UPDATE_MODULE = "/events/admin/update-module";
export const ADMIN_DELETE_MODULE = "/events/admin/delete-module";
export const ADMIN_ADD_SUB_MODULE = "/events/admin/add-submodule";
export const ADMIN_UPDATE_SUB_MODULE = "/events/admin/edit-submodule";
export const ADMIN_DELETE_SUB_MODULE = "/events/admin/delete-submodule";
export const ADMIN_CHECK_LINKING = "/events/admin/check-linking";
export const ADMIN_DELETE_LINKING = "/events/admin/delete-linking";
export const ADMIN_GET_MODULES = "/events/admin/get-modules";
export const ADMIN_GET_APP_LIST = "/events/admin/get-app-list";
export const ADMIN_ADD_ASYNCHRONOUS_CONTENT =
  "/events/admin/add-asynchronous-content";
export const ADMIN_DELETE_ASYNCHRONOUS_CONTENT =
  "/events/admin/delete-asynchronous-content";
export const ADMIN_ADD_ASYNCHRONOUS_CONTENT_ADVANCED =
  "/events/admin/add-asynchronous-content-advanced";
export const ADMIN_SECTION_DATA_CREATE = "/events/admin/section-data/create";
export const ADMIN_SECTION_DATA_UPDATE = "/events/admin/section-data/update";
export const ADMIN_SECTION_DATA_DELETE = "/events/admin/section-data/delete";
export const ADMIN_ASYNCHRONOUS_CONTENT_GET =
  "/events/admin/asynchronous-content";
export const ADMIN_REORDERING_MODULES = "/events/admin/modules/reorder";
export const ADMIN_REORDERING_SUB_MODULES = "/events/admin/sub-modules/reorder";
export const ADMIN_ASSIGNMENT_CREATE = "/events/admin/assignment/create";
export const ADMIN_QUIZ_CREATE = "/events/admin/quiz/create";
export const ADMIN_PROGRESS_REPORT_GET =
  "/events/admin/report/overall-progress";
export const ADMIN_APP_RESPONSES_GET = "/events/admin/get-app-responses";
export const USER_ASSIGNMENT_SUBMIT = "/events/public/assignment/submit";
export const USER_QUIZ_SUBMIT = "/events/public/quiz/submit";

export const USER_GET_MODULES = "/events/public/get-modules";
export const USER_GET_SUB_MODULE_DATA = "/events/public/sub-module-data";
export const USER_SECTION_DATA_GET = "/events/public/section-data";
export const USER_COURSE_PROGRESS = "/events/public/overall-progress";
export const USER_SUB_MODULE_PROGRESS = "/events/public/sub-module-progress";
export const USER_SUB_MODULE_PROGRESS_UPDATE =
  "/events/public/sub-module-progress/update";

export const USER_WORKSPACES = "/users/workspaces";
export const USER_DETAILS = "/users/get-details";
export const BBOA_REORDER_FACTORS = "/bboa/reorder-factors";
export const BBOA_DELETE_FACTOR = "/bboa/factor/delete";
export const BLOG_GET_BY_USER = "/blog/user";
export const BLOG_GET_BY_ID = "/blog/id";
